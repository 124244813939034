<template>
  <div class="relative">
    <div
      class="list-chosen-accounts overflow-dots"
      :class="{
        'cursor-pointer': !loading, 
        'cursor-default': loading, 
        'grey-border': !showInvalid, 
        'red-border': !loading && showInvalid
      }"
      @click.prevent.stop="openAccounts()"
      @keydown.tab.stop="showAccounts = false"
    >
      <span
        v-if="loading"
        class="animation-pulse"
      >
        {{ $filters.localizeFilter('Loading') }}...
      </span>
      
      <div
        v-else
        class="flex_row width_100 overflow-dots"
      >
        <div class="flex_column overflow-dots">
          <div
            v-if="getLimitNumber('maxCards') && showCards && cardId && cards[cardId] && cards[cardId].name"
            class="italic width_100 overflow-dots"
          >
            {{ cards[cardId].name }}
          </div>
          
          <div 
            v-else-if="accountId && accounts[accountId] && accounts[accountId].name"
            class="width_100 overflow-dots"
          >
            {{ accounts[accountId].name }}
          </div>

          <div 
            v-else-if="!canClickBank || !bankId"
            class="width_100 overflow-dots"
          >
            {{ $filters.localizeFilter('Account') }}
          </div>

          <div
            v-if="getLimitNumber('maxBanks')"
            class="overflow-dots"
            :class="{
              'color-main fw-thin': !bankId || accountId || cardId, 
              'dark-gret-text': bankId && !accountId && !cardId
            }"
          >
            <span v-if="bankId && bankId === 'nobank'">{{ $filters.localizeFilter('NoBank') }}</span>
            <span v-else-if="bankId && banks[bankId] && banks[bankId].name">{{ banks[bankId].name }}</span>
            <span v-else-if="!bankId && !accountId">{{ $filters.localizeFilter('Bank') }}</span>
          </div>
        </div>

        <div
          v-if="!showStopFilter && accountId && (defaultAccount.accountId !== accountId || defaultAccount.cardId !== cardId)"
          class="vertical_align_center mx-0_5"
        >
          <div
            v-tooltip="{
              text: $filters.localizeFilter('SetAsMainAccount'),
              padding: 2
            }"
            class="icon icon-grey icon-star_border cursor-pointer square-1_25"
            @click.prevent.stop="setDefaultAccount({ accountId, cardId })"
          />
        </div>
      </div>

      <div
        v-if="!loading"
        class="list-input-unfold-arrows-block"
      >
        <div class="list-input-unfold-arrows icon-unfold_more cursor-pointer" />
      </div>
    </div>
    
    <ul
      v-if="showAccounts && !loading"
      v-click-outside="closeAccounts"
      class="list-opened-block max-h-14_0"
    >
      <li
        v-if="showStopFilter"
        class="list-opened-li cursor-pointer overflow-dots pl-1_0"
        @click.prevent.stop="chooseOption({})"
      >
        {{ $filters.localizeFilter('DontFilter') }}
      </li>
      
      <li
        v-if="!accountsArr.length"
        class="list-opened-li cursor-pointer overflow-dots pl-1_0"
        @click.prevent.stop="showAccounts = false"
      >
        {{ $filters.localizeFilter('Empty') }}...
      </li>

      <div v-else> 
        <div v-if="getLimitNumber('maxBanks')">
          <div
            v-for="(bnkId, bankIdx) in banksArr"
            :key="bankIdx"
          >
            <li
              v-if="bankAccounts(bnkId).length"
              v-long-hover="{
                hoverDuration: 5000,
                func: expandList,
                funcParam: { bankId: bnkId }
              }"
              class="bank-accounts-header rounded-top-0_375 overflow-dots cursor-pointer"
              :class="{'mt-0_125': bankIdx !== 0}"
              @click.prevent.stop="chooseOption({ bankId: bnkId} )"
            >
              <span
                v-if="banks[bnkId] && banks[bnkId].name"
                v-tooltip="{
                  text: banks[bnkId].name,
                  padding: 4
                }"
                class="overflow-dots width_100"
              >
                {{ banks[bnkId].name }}
              </span>

              <span v-else class="overflow-dots">{{ $filters.localizeFilter('Bank') }}</span>

              <ShowArrow
                v-if="openBank !== bnkId"
                :key="bnkId + '-1' + openBank"
                @clicked="expandList({ bankId: bnkId })"
              />

              <ShownArrow
                v-else
                :key="bnkId + '-2' + openBank"
                @clicked="expandList({ bankId: bnkId })"
              />
            </li>

            <div
              v-if="bankAccounts(bnkId).length && openBank === bnkId"
            >
              <div
                v-for="accId in bankAccounts(bnkId)"
                :key="accId"
              >
                <li
                  class="account-header cursor-pointer overflow-dots"
                  @click.prevent.stop="chooseOption({ bankId: bnkId, accountId: accId })"
                  v-long-hover="{
                    hoverDuration: 5000,
                    func: expandList,
                    funcParam: { accountId: accId }
                  }"
                >
                  <div class="flex_row width_100 overflow-dots">
                    <div
                      v-if="accounts[accId] && accounts[accId].name"
                      v-tooltip="{
                        text: accounts[accId].name,
                        padding: 4
                      }"
                      class="overflow-dots"
                    >
                      {{ accounts[accId].name }}
                    </div>

                    <div v-else>{{ $filters.localizeFilter('Account') }}</div>

                    <div
                      v-if="defaultAccount.accountId === accId"
                      class="ml-0_125 vertical_align_center"
                    >
                      <div class="icon icon-grey icon-star cursor-pointer square-1" />
                    </div>
                  </div>

                  <span v-if="getLimitNumber('maxCards') && showCards && accountCards(accId).length && ((currencyCode && accounts[accId] && accounts[accId].currencyCode === currencyCode) || !currencyCode)">
                    <ShowArrow
                      v-if="openAccount !== accId"
                      :key="accId + '-3' + openAccount"
                      @clicked="expandList({ accountId: accId })"
                    />

                    <ShownArrow
                      v-else
                      :key="accId + '-4' + openAccount"
                      @clicked="expandList({ accountId: accId})"
                    />
                  </span>
                </li>

                <div v-if="openAccount === accId && getLimitNumber('maxCards') && showCards && accountCards(accId).length && ((currencyCode && accounts[accId] && accounts[accId].currencyCode === currencyCode) || !currencyCode)">
                  <div
                    v-for="crdId in accountCards(accId)"
                    :key="crdId"
                  >
                    <li
                      v-if="!useCards.length || useCards.indexOf(crdId) >= 0"
                      class="account-header cursor-pointer overflow-dots italic fw-light fs-xsmall"
                      @click.prevent.stop="chooseOption({ bankId: bnkId, accountId: accId, cardId: crdId })"
                    >
                      <div
                        v-if="cards[crdId] && cards[crdId].name"
                        v-tooltip="{
                          text: cards[crdId].name,
                          padding: 4
                        }"
                        class="pl-1_0 overflow-dots"
                      >
                        {{ cards[crdId].name }}
                      </div>

                      <div v-else class="pl-1_0 overflow-dots">{{ $filters.localizeFilter('Card') }}</div>

                      <div
                        v-if="defaultAccount.cardId === crdId"
                        class="ml-0_125 vertical_align_center"
                      >
                        <div class="icon icon-grey icon-star cursor-pointer square-0_75" />
                      </div>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <li
            v-if="showNoBank || bankAccounts(null).length"
            v-long-hover="{
              hoverDuration: 5000,
              func: expandList,
              funcParam: { bankId: 'nobank' }
            }"
            class="bank-accounts-header rounded-top-0_375 overflow-dots cursor-pointer"
            @click.prevent.stop="chooseOption({ bankId: 'nobank' })"
          >
            <div class="overflow-dots width_100">
              {{ $filters.localizeFilter('NoBank') }}
            </div>

            <ShowArrow
              v-if="openBank !== 'nobank'"
              :key="'nobank' + '-5' + openBank"
              @clicked="expandList({ bankId: 'nobank' })"
            />

            <ShownArrow
              v-else
              :key="'nobank' + '-6' + openBank"
              @clicked="expandList({ bankId: 'nobank' })"
            />
          </li>

          <div
            v-if="bankAccounts(null).length && openBank === 'nobank'"
          >
            <div
              v-for="accId in bankAccounts(null)"
              :key="accId"
            >
              <li
                class="account-header cursor-pointer overflow-dots"
                v-long-hover="{
                  hoverDuration: 5000,
                  func: expandList,
                  funcParam: { accountId: accId }
                }"
                @click.prevent.stop="chooseOption({ accountId: accId })"
              >
                <div class="flex_row width_100 overflow-dots">
                  <span
                    v-if="accounts[accId] && accounts[accId].name"
                    v-tooltip="{
                      text: accounts[accId].name,
                      padding: 4
                    }"
                    class="overflow-dots"
                  >
                    {{ accounts[accId].name }}
                  </span>

                  <span v-else class="overflow-dots">{{ $filters.localizeFilter('Account') }}</span>

                  <div
                    v-if="defaultAccount.accountId === accId"
                    class="ml-0_125 vertical_align_center"
                  >
                    <div class="icon icon-grey icon-star cursor-pointer square-1" />
                  </div>
                </div>

                <span v-if="getLimitNumber('maxCards') && showCards && accountCards(accId).length && ((currencyCode && accounts[accId] && accounts[accId].currencyCode === currencyCode) || !currencyCode)">
                  <ShowArrow
                    v-if="openAccount !== accId"
                    :key="accId + '-7' + openAccount"
                    @clicked="expandList({ accountId: accId })"
                  />

                  <ShownArrow
                    v-else
                    :key="accId + '-8' + openAccount"
                    @clicked="expandList({ accountId: accId })"
                  />
                </span>
              </li>

              <div v-if="openAccount === accId && getLimitNumber('maxCards') && showCards && accountCards(accId).length && ((currencyCode && accounts[accId] && accounts[accId].currencyCode === currencyCode) || !currencyCode)">
                <div
                  v-for="crdId in accountCards(accId)"
                  :key="crdId"
                >
                  <li
                    v-if="!useCards.length || useCards.indexOf(crdId) >= 0"
                    class="account-header cursor-pointer overflow-dots italic fw-light fs-xsmall"
                    @click.prevent.stop="chooseOption({ accountId: accId, cardId: crdId })"
                  >
                    <span
                      v-if="cards[crdId] && cards[crdId].name"
                      v-tooltip="{
                        text: cards[crdId].name,
                        padding: 4
                      }"
                      class="pl-1_0 overflow-dots"
                    >
                      {{ cards[crdId].name }}
                    </span>

                    <span v-else class="pl-1_0 overflow-dots">{{ $filters.localizeFilter('Card') }}</span>

                    <div
                      v-if="defaultAccount.cardId === crdId"
                      class="ml-0_125 vertical_align_center"
                    >
                      <div class="icon icon-grey icon-star cursor-pointer square-0_75" />
                    </div>
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div
            v-for="(accId, accIdx) in accountsArr"
            :key="accIdx"
          >
            <li
              class="account-header cursor-pointer overflow-dots"
              v-long-hover="{
                hoverDuration: 5000,
                func: expandList,
                funcParam: { accountId: accId }
              }"
              @click.prevent.stop="chooseOption({ accountId: accId })"
            >
              <div class="flex_row width_100 overflow-dots">
                <span 
                  v-if="accounts[accId] && accounts[accId].name"
                  v-tooltip="{
                    text: accounts[accId].name,
                    padding: 4
                  }"
                  class="overflow-dots"
                >
                  {{ accounts[accId].name }}
                </span>
                
                <span v-else class="overflow-dots">
                  {{ $filters.localizeFilter('Account') }}
                </span>

                <div
                  v-if="defaultAccount.accountId === accId"
                  class="ml-0_125 vertical_align_center"
                >
                  <div class="icon icon-grey icon-star cursor-pointer square-1" />
                </div>
              </div>

              <span v-if="getLimitNumber('maxCards') && showCards && accountCards(accId).length && ((currencyCode && accounts[accId] && accounts[accId].currencyCode === currencyCode) || !currencyCode)">
                <ShowArrow
                  v-if="openAccount !== accId"
                  :key="accId + '-9' + openAccount"
                  @clicked="expandList({ accountId: accId })"
                />

                <ShownArrow
                  v-else
                  :key="accId + '-10' + openAccount"
                  @clicked="expandList({ accountId: accId })"
                />
              </span>
            </li>

            <div
              v-if="openAccount === accId && getLimitNumber('maxCards') && showCards && accountCards(accId).length && ((currencyCode && accounts[accId] && accounts[accId].currencyCode === currencyCode) || !currencyCode)"
            >
              <div
                v-for="crdId in accountCards(accId)"
                :key="crdId"
              >
                <li
                  v-if="!useCards.length || useCards.indexOf(crdId) >= 0"
                  class="account-header cursor-pointer overflow-dots italic fw-light fs-xsmall"
                  @click.prevent.stop="chooseOption({ accountId: accId, cardId: crdId })"
                >
                  <span
                    v-if="cards[crdId] && cards[crdId].name"
                    v-tooltip="{
                      text: cards[crdId].name,
                      padding: 4
                    }"
                    class="pl-1_0 overflow-dots"
                  >
                    {{ cards[crdId].name }}
                  </span>

                  <span v-else class="pl-1_0 overflow-dots">{{ $filters.localizeFilter('Card') }}</span>

                  <div
                    v-if="defaultAccount.cardId === crdId"
                    class="ml-0_125 vertical_align_center"
                  >
                    <div class="icon icon-grey icon-star cursor-pointer square-0_75" />
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TransactionAccountComponent',
  components: { 
    ShowArrow: defineAsyncComponent(() => import('@/components/UI/buttons/ShowArrow')),
    ShownArrow: defineAsyncComponent(() => import('@/components/UI/buttons/ShownArrow'))
  },
  props: {
    setAccountId: {
      type: String,
      default: null
    },
    setBankId: {
      type: String,
      default: null
    },
    setCardId: {
      type: String,
      default: null
    },
    useAccounts: {
      type: Array,
      default: function () { return [] }
    },
    hideAccounts: {
      type: Array,
      default: function () { return [] }
    },
    useCards: {
      type: Array,
      default: function () { return [] }
    },
    currencyCode: {
      type: String,
      default: null
    },
    showCards: {
      type: Boolean,
      default: true
    },
    showStopFilter: {
      type: Boolean,
      default: false
    },
    canClickBank: {
      type: Boolean,
      default: false
    },
    showNoBank: {
      type: Boolean,
      default: false
    },
    showInvalid: {
      type: Boolean,
      default: false
    },
    nullAccountAllowed: {
      type: Boolean,
      default: false
    },
    noDefaultAccount: {
      type: Boolean,
      default: false
    },
    from: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: true,
    accountId: null,
    bankId: null,
    cardId: null,
    accountsArr: [],
    cardsArr: [],
    banksArr: [],
    showAccounts: false,
    openBank: null,
    openAccount: null
  }),
  computed: {
    ...mapGetters([
      'accounts',
      'banks',
      'cards',
      'defaultAccount',
      'getLimitNumber'
    ]),
  },
  watch: {
    currencyCode (newCurrCode) {
      if (newCurrCode && this.accountId && this.accounts[this.accountId] && this.accounts[this.accountId].currencyCode && this.accounts[this.accountId].currencyCode !== newCurrCode) {
        this.accountId = null
        this.cardId = null
        this.bankId = null
      }
    },
    hideAccounts (newData) {
      if (newData) {
        this.createData()
      }
    }
  },
  async created() {
    this.loading = true

    if(!this.$store.getters.loadedAll('accountsActive')) {
      await this.$store.dispatch('fetchAccounts', true)
    }

    if(!this.$store.getters.loadedAll('cardsActive') && this.getLimitNumber('maxCards')) {
      await this.$store.dispatch('fetchCards', { active: true })
    }

    if(!this.$store.getters.loadedAll('banks') && this.getLimitNumber('maxBanks')) {
      await this.$store.dispatch('fetchBanks')
    }

    this.createData()

    this.loading = false
  },
  methods: {
    createData() {
      if (this.useAccounts && this.useAccounts.length) {
        this.accountsArr = this.useAccounts
      } else {
        this.accountsArr = Object.keys(this.accounts)

        this.accountsArr = this.accountsArr.filter(accountId => {
          return this.accounts[accountId].active
        })
      }

      if (this.hideAccounts.length) {
        this.accountsArr = this.accountsArr.filter(accountId => {
          return !this.hideAccounts.includes(accountId)
        })
      }

      if (this.currencyCode) {
        this.accountsArr = this.accountsArr.filter(accountId => {
          return this.accounts[accountId].currencyCode === this.currencyCode
        })
      }

      if (this.setAccountId || this.setCardId) {
        if (this.setAccountId) { this.accountId = this.setAccountId }
        if (this.setCardId && this.getLimitNumber('maxCards')) { this.cardId = this.setCardId }
      } else if (!this.showStopFilter) {
        if (!this.noDefaultAccount && this.defaultAccount.accountId && this.hideAccounts.indexOf(this.defaultAccount.accountId) < 0) {
          if (!this.currencyCode || (this.accounts[this.defaultAccount.accountId] && this.currencyCode === this.accounts[this.defaultAccount.accountId].currencyCode)) {
            this.accountId = this.defaultAccount.accountId

            if (this.defaultAccount.cardId && this.cards[this.defaultAccount.cardId] && this.getLimitNumber('maxCards')) {
              this.cardId = this.defaultAccount.cardId
            }
          }
        }
      }

      if (this.setBankId) {
        this.bankId = this.setBankId
      } else if (this.accountId && this.accounts[this.accountId] && this.accounts[this.accountId].bankId) {
        this.bankId = this.accounts[this.accountId].bankId
      }

      for (const accountIdx in this.accountsArr) {
        const accountId = this.accountsArr[accountIdx]      
        if (this.accounts[accountId] && this.accounts[accountId].bankId && this.banks[this.accounts[accountId].bankId] && this.banksArr.indexOf(this.accounts[accountId].bankId) < 0) {
          this.banksArr.push(this.accounts[accountId].bankId)
        }
      }

      if (this.getLimitNumber('maxCards')) {
        if (this.useCards.length) {
          this.cardsArr = this.useCards
        } else {
          this.cardsArr = Object.keys(this.cards)
          this.cardsArr = this.cardsArr.filter(cardId => {
            return (this.cards[cardId] && this.cards[cardId].active)
          })
        }
      }

      this.accountsArr = this.sortByName(this.accountsArr, 'accounts')
      this.banksArr = this.sortByName(this.banksArr, 'banks')
      this.cardsArr = this.sortByName(this.cardsArr, 'cards')

      this.setNewAccount('set-new-account')

      const nobankAccounts = this.bankAccounts(null)
      if (this.banksArr.length === 1 && !nobankAccounts.length) { this.openBank = this.banksArr[0] }
      if (!this.banksArr.length && nobankAccounts.length) { this.openBank = 'nobank' }
      
      if (this.bankId) { this.openBank = this.bankId }
      if (this.accountId) {
        this.openAccount = this.accountId
        if (!this.accounts[this.accountId].bankId) {
          this.openBank = 'nobank'
        }
      }
    },
    bankAccounts(bankId) {
      let accounts = this.accountsArr

      if (accounts.length) {
        accounts = accounts.filter(accountId => {
          if (bankId) {
            return this.accounts[accountId].bankId === bankId
          } else {
            return !this.accounts[accountId].bankId
          }
        })
      }

      return accounts
    },
    accountCards(accountId) {
      if (!this.getLimitNumber('maxCards')) { return [] }
      
      let cards = this.cardsArr

      if (cards.length) {
        cards = cards.filter(cardId => {
          return (this.cards[cardId] && this.cards[cardId].accountId === accountId)
        })
      }

      return cards
    },
    sortByName(arr, type) {
      if (arr.length > 1 && type && this[type]) {
        arr.sort((a, b) => {
          let nameA = this[type][a].name.toLowerCase()
          let nameB = this[type][b].name.toLowerCase()
          if (nameA < nameB)
            return -1;
          if (nameA > nameB)
            return 1;
          return 0
        })
      }

      return arr
    },
    async chooseOption({ bankId = null, accountId = null, cardId = null }) {
      this.expandList({ bankId, accountId })

      if (!accountId && (bankId || cardId)) {
        if (!this.nullAccountAllowed) {
          return
        } else {
          if (!this.canClickBank) { return }
        }
      }

      this.closeAccounts()

      this.accountId = accountId
      this.cardId = cardId
      this.bankId = bankId

      this.setNewAccount('save-new-account')
    },
    setNewAccount(type) {
      this.$emit(type, {
        accountId: this.accountId,
        cardId: this.cardId,
        bankId: this.bankId,
      })
    },
    openAccounts() {
      if (!this.loading) { this.showAccounts = !this.showAccounts }
    },
    closeAccounts () {
      this.showAccounts = false
    },
    expandList({ bankId, accountId }) {
      if (!bankId && !accountId) {
        this.openBank = null
        this.openAccount = null
        return
      }

      const setAccount = () => {
        if (!this.openAccount) {
          this.openAccount = accountId
        } else {
          if (this.openAccount === accountId) {
            this.openAccount = null
          } else {
            this.openAccount = accountId
          }
        }
      }

      if (bankId && accountId) {
        if (this.openBank === bankId) {
          if (this.openAccount !== accountId) {
            setAccount()
          }
        } else {
          this.openAccount = null
          this.openBank = bankId
        }
        return
      }
      
      if (bankId) {
        this.openAccount = null

        if (!this.openBank) {
          this.openBank = bankId
        } else {
          if (this.openBank === bankId) {
            this.openBank = null
          } else {
            this.openBank = bankId
          }
        }
      }

      if (accountId) {
        setAccount()
      }
    },
    async setDefaultAccount({ accountId, cardId }) {
      this.$store.dispatch('saveDefaultAccount', { accountId, cardId, from: this.from })
    }
  }
}
</script>